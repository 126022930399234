<template>  
  <div class="uk-container test-kits">
    <UserHeader />
    <h1>My Account</h1>

    <div class="register-promo" uk-grid>
      <div class="uk-width-3-5@s">
        <div class="ucari-card-register-kit uk-margin-top uk-margin-bottom">
          <div class="ucari-card-title">Have a new UCARI Intolerance Kit? Register now.</div>
          <router-link to="/register-kit" class="uk-button uk-button-primary">Register Kit</router-link>
        </div>
      </div>
      <div class="ucari-promo uk-width-2-5@s uk-margin-top uk-margin-bottom">
        <UcariPromo /> 
      </div>
    </div>

    <div>
      <div class="uk-align-center">
        <div v-if="filteredData && filteredData.length">
          <div class="ucari-card" v-for="item in filteredData" :key="item.KitId">
            <div class="ucari-card-title">
              <span v-if="item.TestKitType === 'Pet'">Pet </span>Intolerance Kit<span v-if="(item.TestTypeId === 5 || item.TestTypeId === 9) && item.TypeName"> - {{ item.TypeName }}</span>
            </div>
            <div class="uk-child-width-expand@s uk-grid">        
              <div class="columns">
                <label>Name</label>
                <div>
                  {{ item.FirstName || item.PetName }} {{ item.LastName }}
                </div>
              </div>          
              <div class="columns">
                <label>Kit ID</label>
                <div>{{ item.SerialNumber || 'N/A' }}</div>
              </div>
              <div class="columns">
                <label>Registered On</label>
                <div v-if="item.RegisteredOnUtc">
                  {{ convertUtcToLocal(item.RegisteredOnUtc) }}
                </div>
                <div v-else>--</div>
              </div>
              <div class="columns">
                <label>Received On</label>
                <div v-if="item.ReceivedDateUtc">
                  {{ convertUtcToLocal(item.ReceivedDateUtc) }}
                </div>
                <div v-else>--</div>
              </div>
              <div class="columns">
                <label>Result Posted On</label>
                <div v-if="item.ResultDateUtc">
                  {{ convertUtcToLocal(item.ResultDateUtc) }}
                </div>
                <div v-else>--</div>
              </div>
            </div>
            <div class="test-kit-status" :class="{
                complete: item.ResultDateUtc,
                received: item.ReceivedDateUtc && !item.ResultDateUtc
              }">
              <div v-if="item.ResultDateUtc">Complete</div>
              <div v-else-if="item.ReceivedDateUtc && !item.ResultDateUtc">Received</div>
            </div>
            <div class="uk-margin-top">
              <a v-if="item.ResultDateUtc" :href="`/test-results?id=${item.KitId}`" class="uk-button uk-button-primary">
                  View Test Results
              </a>
            </div>
          </div>
        </div>
        <p v-else-if="isLoading">Loading...</p>
        <p v-else-if="error">There was an error loading the test kits. Please try again later.</p>
        <p v-else>No test kits found.</p>
      </div>
    </div>
  </div>
  <UcariFooter />
</template>

<script>
import axios from 'axios';
import UserHeader from './UserHeader.vue'; 
import UcariFooter from './UcariFooter.vue'; 
import UcariPromo from './UcariPromo.vue'; 
import { convertUtcToLocal } from '@/utils/common';

export default {
  created() {
    document.title = 'My Account | UCARI';
    this.loadCredentials();
  },  
  components: {
    UserHeader,  
    UcariFooter,
    UcariPromo
  },
  data() {
    return {
      data: [],
      isLoading: false,
      error: false,
      accessToken: '',
      cid: ''
    };
  },
  mounted() {
    this.fetchItems();
  },
  computed: {
    filteredData() {
      return this.data.items || []; 
    }
  },  
  methods: {
    loadCredentials() {
      this.accessToken = localStorage.getItem('accessToken');
      this.cid = localStorage.getItem('cid');
    },
    async fetchItems() {
      try {
        this.isLoading = true;
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/test-kits`, {
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid
          }
        });
        this.data = response.data;
        this.isLoading = false;
      } catch (error) {
        console.error("There was an error fetching the data!", error);
        this.error = true;
        this.isLoading = false;
      }
    },
    convertUtcToLocal
  }
}
</script>
